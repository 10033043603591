import "core-js/modules/esnext.iterator.map.js";
import { verifyGoodsPrice, queryBasicPlanSelections } from '@/api/goodsSetting';
export default function useDetailHooks() {
  const detailFormInit = {
    prodType: '',
    pkg: '',
    prodId: '',
    remark: '',
    productNativeInfo: [{
      key: 1,
      lang: 'en-US',
      langOptions: [],
      prodDescription: '',
      prodName: ''
    }],
    prodPrice: '',
    prodPriceCurrency: 'USD',
    prodBasePlan: {
      billingCycle: 'P1W',
      gracePeriod: 'P3D',
      resubscribe: '10',
      offerChanges: '0',
      timeExtension: '10',
      planDuration: 'P1D'
    },
    prodGracePlan: null
    // prodGracePlan: {
    //   regionConfigList: [],
    //   regionTargeting: {
    //     targeting: '0',
    //     targetingRuleScope: '0'
    //   },
    //   type: '0',
    //   duration: {
    //     time: 3,
    //     period: 'D'
    //   },
    //   productNativePrice: {
    //     discountType: 0,
    //     regionCode: '',
    //     prodPriceCurrency: '',
    //     prodPrice: '',
    //     originProdPrice: '',
    //     regionName: ''
    //   }
    // } // 优惠方案
  }; // 详情页初始化用的结构
  const describtionMap = [{
    nameRule: {
      placeholder: '商品名称, 最多55字符',
      maxLength: 55
    },
    desRule: {
      placeholder: '商品描述, 最多200字符',
      maxLength: 200
    }
  }, {
    nameRule: {
      placeholder: '订阅名称, 最多55字符',
      maxLength: 55
    },
    desRule: {
      placeholder: '订阅描述, 最多40字符',
      maxLength: 40
    }
  }];
  const describtionKey = {
    '31': describtionMap[1],
    '32': describtionMap[1],
    '10': describtionMap[0],
    '20': describtionMap[0]
  }; // 针对不同类型配置规则
  const prodTypeOptions = [{
    label: '消耗型',
    describtion: '如"金币"、"钻石"等可消耗的商品；',
    value: '20'
  }, {
    label: '非消耗型',
    describtion: '如"去广告"等只能购买一次的商品；',
    value: '10'
  }, {
    label: '非自动续期订阅',
    describtion: '如"VIP月卡”，到期后不自动续费；',
    value: '31'
  }, {
    label: '自动续期订阅',
    describtion: '如"VIP月卡”，到期后自动续费，直至用户取消订阅；',
    value: '32'
  }]; // 商品类型下拉框
  const huaweiProdTypeOptions = [{
    label: '消耗型',
    describtion: '如"金币"、"钻石"等可消耗的商品；',
    value: '20'
  }, {
    label: '非消耗型',
    describtion: '如"去广告"等只能购买一次的商品；',
    value: '10'
  }]; // 商品类型下拉框
  const overDateOptions = [{
    label: '不允许',
    value: '0'
  }, {
    label: '允许',
    value: '10'
  }]; // 是否延期下拉框
  const basePlanGracePeriodType = ref([{
    label: '3天',
    value: 'P3D'
  }]); //宽限期下拉框
  const basePlanChangesType = ref([{
    label: '在下个结算日收费',
    value: '0'
  }]); //客户基础方案和优惠变更下拉框
  const basePlanBillingCycleType = ref([{
    label: '每周',
    value: 'P1W'
  }]); //结算期下拉框
  const basePlanDurationType = ref([{
    label: '1天',
    value: 'P1D'
  }]); // 时长下拉框
  const basePlanResubscribeType = ref([{
    label: '允许',
    value: '10'
  }, {
    label: '不允许',
    value: '0'
  }]); // 重新订阅
  const gracePlanTargetingType = ref([]); // 资格条件
  const gracePlanType = ref([]); // 优惠类型
  const validatePid = (_rule, value) => {
    let validType = /^[a-zA-Z0-9_]+([.]{1,}[a-zA-Z0-9_]+){0,}$/;
    let validNumber = /^[a-zA-Z0-9._]{1,140}$/; // 动态拼接正则规则
    if (!value) {
      return Promise.reject('请填写完整内购ID');
    }
    if (!validType.test(value) || !validNumber.test(value)) {
      return Promise.reject('请按照正确格式填写内购ID,140字符以内大小写英文,中间允许出现._符号');
    }
    return Promise.resolve();
  }; // 校验内购ID合法性 140字符以内大小写英文,中间允许出现._符号
  const validateIosPid = (_rule, value) => {
    let validType = /^[a-zA-Z0-9_]+([.]{1,}[a-zA-Z0-9_]+){0,}$/;
    let validNumber = /^[a-zA-Z0-9._]{1,100}$/; // 动态拼接正则规则
    if (!value) {
      return Promise.reject('请填写完整内购ID');
    }
    if (!validType.test(value) || !validNumber.test(value)) {
      return Promise.reject('请按照正确格式填写内购ID,100字符以内小写英文,中间允许出现._符号');
    }
    return Promise.resolve();
  }; // 校验内购ID合法性 100字符以内小写英文,中间允许出现._符号
  const validatePidAuto = (_rule, value) => {
    let validType = /^[a-z0-9_]+([.]{1,}[a-z0-9_]+){0,}$/;
    let validNumber = /^[a-z0-9._]{1,140}$/; // 动态拼接正则规则
    if (!value) {
      return Promise.reject('请填写完整内购ID');
    }
    if (!validType.test(value) || !validNumber.test(value)) {
      return Promise.reject('请按照正确格式填写内购ID,140字符以内小写英文,中间允许出现._符号');
    }
    return Promise.resolve();
  }; // 订阅类商品校验内购ID合法性 140字符以内小写英文,中间允许出现._符号
  const validateGooglePidAuto = (_rule, value) => {
    let validType = /^[a-z0-9_]+([.]{1,}[a-z0-9_]+){0,}$/;
    let validNumber = /^[a-z0-9._]{1,140}$/; // 动态拼接正则规则
    if (!value) {
      return Promise.reject('请填写完整内购ID');
    }
    if (!validType.test(value) || !validNumber.test(value)) {
      return Promise.reject('请按照正确格式填写内购ID,140字符以内小写英文,中间允许出现._符号');
    }
    return Promise.resolve();
  }; // 订阅类商品校验内购ID合法性 140字符以内小写英文,中间允许出现._符号
  const validateHuaweiPid = (_rule, value) => {
    let validType = /^[a-zA-Z0-9_]+([.]{1,}[a-zA-Z0-9_]+){0,}$/;
    let validNumber = /^[a-zA-Z0-9._]{1,148}$/; // 动态拼接正则规则
    if (!value) {
      return Promise.reject('请填写完整内购ID');
    }
    if (!validType.test(value) || !validNumber.test(value)) {
      return Promise.reject('请按照正确格式填写内购ID,148字符以内小写英文,中间允许出现._符号');
    }
    return Promise.resolve();
  }; // 校验内购ID合法性 148字符以内小写英文,中间允许出现._符号
  const validateAmazonPid = (_rule, value) => {
    let validType = /^[a-zA-Z0-9_]+([.]{1,}[a-zA-Z0-9_]+){0,}$/;
    let validNumber = /^[a-zA-Z0-9._]{1,150}$/; // 动态拼接正则规则
    if (!value) {
      return Promise.reject('请填写完整内购ID');
    }
    if (!validType.test(value) || !validNumber.test(value)) {
      return Promise.reject('请按照正确格式填写内购ID,150字符以内大小写英文,中间允许出现._符号');
    }
    return Promise.resolve();
  }; // 校验内购ID合法性 150字符以内大小写英文,中间允许出现._符号
  const validateXVOTPid = (_rule, value) => {
    let validType = /^[a-zA-Z0-9_]+([.]{1,}[a-zA-Z0-9_]+){0,}$/;
    let validNumber = /^[a-zA-Z0-9._]{1,50}$/; // 动态拼接正则规则
    if (!value) {
      return Promise.reject('请填写完整内购ID');
    }
    if (!validType.test(value) || !validNumber.test(value)) {
      return Promise.reject('请按照正确格式填写内购ID,50字符以内大小写英文,中间允许出现._符号');
    }
    return Promise.resolve();
  }; // 校验内购ID合法性 50字符以内大小写英文,中间允许出现._符号
  const verifyNumber = async params => {
    // 校验价格合法性
    try {
      const res = await verifyGoodsPrice(params);
      return res.code === '0';
    } catch (error) {
      return false;
    }
  }; // 校验价格是否符合范围区间
  const getBasicPlanDateOptions = async prodChnl => {
    const refunc = arr => {
      if (!arr) return [];
      return arr.map(item => {
        item['label'] = item.name;
        return item;
      });
    };
    const res = await queryBasicPlanSelections({
      prodChnl
    });
    try {
      if (res.code === '0') {
        basePlanGracePeriodType.value = refunc(res.data.basePlanGracePeriodType);
        basePlanChangesType.value = refunc(res.data.basePlanChangesType);
        basePlanBillingCycleType.value = refunc(res.data.basePlanBillingCycleType);
        basePlanDurationType.value = refunc(res.data.basePlanDurationType);
        gracePlanTargetingType.value = refunc(res.data.gracePlanTargetingType);
        gracePlanType.value = refunc(res.data.gracePlanType);
      }
    } catch (error) {}
  }; // 获取基础方案中下拉框数据
  return {
    prodTypeOptions,
    huaweiProdTypeOptions,
    overDateOptions,
    basePlanDurationType,
    basePlanBillingCycleType,
    basePlanChangesType,
    basePlanGracePeriodType,
    basePlanResubscribeType,
    gracePlanTargetingType,
    gracePlanType,
    detailFormInit,
    describtionKey,
    validatePid,
    validateIosPid,
    validatePidAuto,
    validateGooglePidAuto,
    validateHuaweiPid,
    validateAmazonPid,
    validateXVOTPid,
    verifyNumber,
    getBasicPlanDateOptions
  };
}