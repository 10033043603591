import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import { queryChnlOption, queryLangOption, queryCountryCoinOptions, queryGoodsOption } from '@/api/goodsSetting';
export default function useGetSelect(prodChnl) {
  const dbtProducts = ref([]); // 产品下拉框
  const chnlOptions = ref([]); // 渠道下拉框
  const langOptions = ref([]); // 语言下拉框
  const judgeOptions = ref([{
    label: '全部',
    value: ''
  }, {
    label: '待提交',
    value: 0
  }, {
    label: '审核中',
    value: 10
  }, {
    label: '已驳回',
    value: 20
  }, {
    label: '已通过',
    value: 30
  }]); // 审核状态下拉框
  const prodStatusOptions = ref([{
    label: '全部',
    value: ''
  }, {
    label: '--',
    value: 0
  }, {
    label: '有效',
    value: 10
  }, {
    label: '失效',
    value: 20
  }]); // 同步状态下拉框
  const publishOptions = ref([{
    label: '全部',
    value: ''
  }, {
    label: '--',
    value: 0
  }, {
    label: '成功',
    value: 10
  }, {
    label: '失败',
    value: 20
  }]); // 商品状态下拉框
  const countryCoinLists = ref([]); // 获取国家-货币选项集合
  const countryToCoin = ref({}); // 定义国家-转货币缩写键值对
  const currencyToCoinSymbol = ref({}); // 定义国家-转货币符号键值对
  const langValToItem = ref({}); // 定义语言缩写值-语言item数据键值对
  const chnlTransToCh = {
    google: '谷歌'
  }; // 定义渠道英汉转译
  const optTypeTransToCh = {
    0: '待提交',
    10: '审核中',
    20: '已驳回',
    30: '审核通过'
  }; // 审核类型转换
  const getDbtProducts = async () => {
    const res = await queryGoodsOption();
    if ((res === null || res === void 0 ? void 0 : res.code) === '0') {
      var _res$data;
      dbtProducts.value = ((_res$data = res.data) === null || _res$data === void 0 ? void 0 : _res$data.map(item => {
        return {
          label: item.appName,
          value: item.dbtId
        };
      })) || [];
    }
  }; // 获取产品信息
  const getChnlOptions = async dbtId => {
    const res = await queryChnlOption({
      dbtId
    });
    if ((res === null || res === void 0 ? void 0 : res.code) === '0') {
      var _res$data2;
      chnlOptions.value = ((_res$data2 = res.data) === null || _res$data2 === void 0 || (_res$data2 = _res$data2.prodChnl) === null || _res$data2 === void 0 ? void 0 : _res$data2.map(item => {
        return {
          label: item.channelName,
          value: item.channelCode
        };
      })) || [];
    }
  }; // 根据产品ID获取渠道信息
  const filterOption = (input, option) => {
    var _option$label, _option$value;
    // 使用label或value进行搜索
    return (option === null || option === void 0 || (_option$label = option.label) === null || _option$label === void 0 ? void 0 : _option$label.toLowerCase().indexOf(input.trim().toLowerCase())) >= 0 || (option === null || option === void 0 || (_option$value = option.value) === null || _option$value === void 0 ? void 0 : _option$value.indexOf(input)) >= 0;
  }; // 模糊搜索
  const getLangOptions = async data => {
    const res = await queryLangOption(data);
    if ((res === null || res === void 0 ? void 0 : res.code) === '0') {
      var _res$data3;
      langOptions.value = ((_res$data3 = res.data) === null || _res$data3 === void 0 ? void 0 : _res$data3.map(item => {
        return {
          label: item.langName,
          value: item.lang
        };
      })) || [];
      langOptions.value.forEach(item => {
        langValToItem[item.value] = item;
      });
    }
  }; // 获取商品语言下拉框数据
  const getCountryCoinOptions = async () => {
    try {
      const res = await queryCountryCoinOptions({
        prodChnl: prodChnl || ''
      });
      if ((res === null || res === void 0 ? void 0 : res.code) === '0') {
        var _res$data4;
        countryCoinLists.value = (_res$data4 = res.data) === null || _res$data4 === void 0 ? void 0 : _res$data4.list;
        if (countryCoinLists.value.length) {
          countryCoinLists.value.forEach(item => {
            // 建立键值对
            countryToCoin.value[item.country] = item.currency;
            currencyToCoinSymbol.value[item.currency] = item.currencyPrefix;
          });
        }
      }
    } catch (error) {}
  };
  return {
    dbtProducts,
    chnlOptions,
    langOptions,
    langValToItem,
    chnlTransToCh,
    countryCoinLists,
    countryToCoin,
    currencyToCoinSymbol,
    optTypeTransToCh,
    judgeOptions,
    prodStatusOptions,
    publishOptions,
    filterOption,
    getDbtProducts,
    getChnlOptions,
    getLangOptions,
    getCountryCoinOptions
  };
}