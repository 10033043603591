import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.map.js";
import { Modal, message } from 'ant-design-vue';
import { createVNode } from 'vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { useRouter } from 'vue-router';
import { queryGoodsList, delGoods, onlineGoods, offlineGoods, exportGoods } from '@/api/goodsSetting';
import { handleExportFile, reportEvent } from '@/common';
export default function useListEvent(selectedRowKeys) {
  const router = useRouter();
  const searchForm = ref({
    dbtId: '',
    prodChnl: '',
    fuzzy: '',
    prodType: '',
    approvalStatuses: '',
    publishStatuses: '',
    prodStatus: '',
    testStatus: null,
    os: ''
  });
  const total = ref(0); // 经过模糊筛选后符合条件的数据量
  const pageIndex = ref(1);
  const pageSize = ref(20);
  const dataSource = ref([]);
  const columns = [{
    title: '序号',
    dataIndex: 'codeNumber',
    width: 60
  }, {
    title: '商品类型',
    dataIndex: 'prodType'
  }, {
    title: '内购ID',
    dataIndex: 'prodId'
  }, {
    title: '备注',
    dataIndex: 'remark'
  }, {
    title: '默认价格',
    dataIndex: 'prodPrice',
    width: 120
  }, {
    title: '审核状态',
    dataIndex: 'approvalStatus'
  }, {
    title: '同步状态',
    dataIndex: 'publishStatus'
  }, {
    title: '商品状态',
    dataIndex: 'prodStatus'
  }, {
    title: '审核类型',
    dataIndex: 'optType'
  }, {
    title: '操作',
    dataIndex: 'handle',
    width: 140,
    align: 'right'
  }];
  const columnsWeixin = [{
    title: '序号',
    dataIndex: 'codeNumber',
    width: 60
  }, {
    title: '商品类型',
    dataIndex: 'prodType'
  }, {
    title: '内购ID',
    dataIndex: 'prodId'
  }, {
    title: '备注',
    dataIndex: 'remark'
  }, {
    title: '默认价格',
    dataIndex: 'prodPrice',
    width: 120
  }, {
    title: '系统',
    dataIndex: 'os',
    width: 120
  }, {
    title: '审核状态',
    dataIndex: 'approvalStatus'
  }, {
    title: '同步状态',
    dataIndex: 'publishStatus'
  }, {
    title: '商品状态',
    dataIndex: 'prodStatus'
  }, {
    title: '审核类型',
    dataIndex: 'optType'
  }, {
    title: '操作',
    dataIndex: 'handle',
    width: 140,
    align: 'right'
  }];
  const appName = ref('');
  const tableLoading = ref(false);
  const getData = async () => {
    var _dataSource$value;
    let startTime = new Date().getTime();
    tableLoading.value = true;
    try {
      const res = await queryGoodsList({
        ...searchForm.value,
        approvalStatuses: searchForm.value.approvalStatuses || searchForm.value.approvalStatuses === 0 ? [searchForm.value.approvalStatuses] : [],
        publishStatuses: searchForm.value.publishStatuses || searchForm.value.publishStatuses === 0 ? [searchForm.value.publishStatuses] : [],
        prodStatus: searchForm.value.prodStatus || searchForm.value.prodStatus === 0 ? searchForm.value.prodStatus : null,
        pageNum: 1,
        pageSize: 100000
      });
      if ((res === null || res === void 0 ? void 0 : res.code) === '0') {
        var _res$data, _res$data2;
        dataSource.value = ((_res$data = res.data) === null || _res$data === void 0 ? void 0 : _res$data.list) || [];
        total.value = ((_res$data2 = res.data) === null || _res$data2 === void 0 ? void 0 : _res$data2.total) || 0;
        selectedRowKeys.value = [];
        reportEvent({
          eventName: '_search',
          properties: {
            ui_name: '商品配置搜索',
            app_name: '',
            return_duration: (new Date().getTime() - startTime) / 1000,
            query_status: 'succeed'
          }
        });
      } else {
        reportEvent({
          eventName: '_search',
          properties: {
            ui_name: '商品配置搜索',
            app_name: '',
            return_duration: (new Date().getTime() - startTime) / 1000,
            query_status: 'failed'
          }
        });
      }
    } catch (error) {
      reportEvent({
        eventName: '_search',
        properties: {
          ui_name: '商品配置搜索',
          app_name: '',
          return_duration: (new Date().getTime() - startTime) / 1000,
          query_status: 'failed'
        }
      });
    }
    dataSource.value = (_dataSource$value = dataSource.value) === null || _dataSource$value === void 0 ? void 0 : _dataSource$value.map((item, index) => {
      item.codeNumber = (pageIndex.value - 1) * pageSize.value + index + 1; // 添加序号
      return item;
    });
    tableLoading.value = false;
  }; // 从后端查询源数据
  const goUpd = item => {
    const pushing = () => {
      router.push({
        name: 'goodsSettingDetail',
        query: {
          dbtId: searchForm.value.dbtId,
          prodChnl: searchForm.value.prodChnl,
          id: item.id
        }
      });
    };
    if (item.publishStatus === 10) {
      // 同步状态成功的话介入提示
      Modal.confirm({
        title: '提示',
        icon: createVNode(ExclamationCircleOutlined),
        content: '该商品已生效，请修改商品信息后，再次提交审核，审核通过后商品信息更新。',
        okText: '确 定',
        okType: 'danger',
        cancelText: '取 消',
        onOk: () => {
          pushing();
        },
        onCancel() {}
      });
    } else {
      pushing();
    }
  }; //点击编辑去详情
  const goAdd = () => {
    if (!searchForm.value.prodChnl) {
      message.error('缺少商品渠道，无法新增');
      return;
    }
    router.push({
      name: 'goodsSettingDetail',
      query: {
        dbtId: searchForm.value.dbtId,
        prodChnl: searchForm.value.prodChnl
      }
    });
  }; // 点击新增去详情
  const searcher = () => {
    pageIndex.value = 1;
    getData();
  }; // 搜索事件
  const goDel = item => {
    Modal.confirm({
      title: '提示',
      icon: createVNode(ExclamationCircleOutlined),
      content: '确定要删除该商品吗?',
      okText: '确 定',
      okType: 'danger',
      cancelText: '取 消',
      onOk: async () => {
        try {
          const res = await delGoods({
            id: item.id,
            prodChnl: searchForm.value.prodChnl
          });
          if (res.code === '0') {
            message.success('删除成功');
            if (pageIndex.value > 1 && dataSource.value.length === 1) {
              pageIndex.value--;
            }
            getData();
            reportEvent({
              eventName: 'element_use',
              properties: {
                'ui_name': '商品配置',
                'element_name': '删除'
              }
            });
          }
        } catch (error) {}
      },
      onCancel() {}
    });
  }; // 删除事件
  const changePage = (val, size) => {
    pageIndex.value = val;
    pageSize.value = size;
    getData();
  }; // 分页事件
  const goOnline = async item => {
    const pushing = async () => {
      try {
        const res = await onlineGoods({
          productList: [{
            id: item.id
          }],
          prodChnl: searchForm.value.prodChnl
        });
        if ((res === null || res === void 0 ? void 0 : res.code) === '0') {
          message.success('启用操作成功');
          getData();
          reportEvent({
            eventName: 'element_use',
            properties: {
              'ui_name': '商品配置',
              'element_name': '启用'
            }
          });
        }
      } catch (error) {}
    };
    if (item.publishStatus === 10) {
      Modal.confirm({
        title: '提示',
        icon: createVNode(ExclamationCircleOutlined),
        content: '该商品已失效，请在点击启用后再次提交审核，审核通过后商品将再次生效。',
        okText: '确 定',
        okType: 'danger',
        cancelText: '取 消',
        onOk: () => {
          pushing();
        },
        onCancel() {}
      });
    } else {
      pushing();
    }
  }; // 上线
  const goOffline = async item => {
    const pushing = async () => {
      try {
        const res = await offlineGoods({
          productList: [{
            id: item.id
          }],
          prodChnl: searchForm.value.prodChnl
        });
        if ((res === null || res === void 0 ? void 0 : res.code) === '0') {
          message.success('停用操作成功');
          getData();
          reportEvent({
            eventName: 'element_use',
            properties: {
              'ui_name': '商品配置',
              'element_name': '停用'
            }
          });
        }
      } catch (error) {}
    };
    if (item.publishStatus === 10) {
      Modal.confirm({
        title: '提示',
        icon: createVNode(ExclamationCircleOutlined),
        content: '该商品已生效，请在点击停用后再次提交审核，审核通过后商品将失效。',
        okText: '确 定',
        okType: 'danger',
        cancelText: '取 消',
        onOk: () => {
          pushing();
        },
        onCancel() {}
      });
    } else {
      pushing();
    }
  }; // 下线
  const exportGoodsExcel = async () => {
    let params = {
      ...searchForm.value,
      approvalStatuses: searchForm.value.approvalStatuses || searchForm.value.approvalStatuses === 0 ? [searchForm.value.approvalStatuses] : [],
      publishStatuses: searchForm.value.publishStatuses || searchForm.value.publishStatuses === 0 ? [searchForm.value.publishStatuses] : [],
      ids: unref(selectedRowKeys)
    };
    handleExportFile(await exportGoods(params), `${appName.value}_商品表`, 'xlsx');
    reportEvent({
      eventName: 'element_use',
      properties: {
        'ui_name': '商品配置',
        'element_name': '导出'
      }
    });
  }; // 导出
  return {
    total,
    pageIndex,
    pageSize,
    dataSource,
    columns,
    columnsWeixin,
    searchForm,
    appName,
    tableLoading,
    getData,
    searcher,
    goAdd,
    goDel,
    goUpd,
    goOnline,
    goOffline,
    changePage,
    exportGoodsExcel
  };
}