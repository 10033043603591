// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import request from './request'

// 查询产品信息
export const queryGoodsOption = () => request('/payAdminServer/app/dimension', null, 'POST_JSON')

// 根据产品ID获取其渠道信息
export const queryChnlOption = (data: { dbtId: string }) => request('/payAdminServer/product/getProdTypeByDbtId', null, 'POST_JSON', data)

// 获取本地语言下拉框数据
export const queryLangOption = (data: { prodChnl: string }) => request('/payAdminServer/product/getNativeInfoList', null, 'POST_JSON', data)

// 获取国家货币关系信息
export const queryCountryCoinOptions = (data:{ prodChnl: string }) => request('/payAdminServer/product/getAccountCountry2Currency', null, 'POST_JSON', data)

// 获取包名默认价格国家信息
export const queryPkgInfo = (data: { dbtId: string, prodChnl: string }) => request('/payAdminServer/product/getAppInfoByDbtIdAndProdChnl', null, 'POST_JSON', data)

// 根据产品ID和渠道获取商品列表信息
export const queryGoodsList = (data: goodsSetting.queryGoodsListParams) => request('/payAdminServer/product/getProductList', null, 'POST_JSON', data)

// 查询商品
export const queryGoods = (data: goodsSetting.queryGoodsParams) => request('/payAdminServer/product/getProduct', null, 'POST_JSON', data)

// 价格信息查询
export const queryPriceList = (data: goodsSetting.queryPriceParams) => request('/payAdminServer/product/price/getDefaultNativePrices', null, 'POST_JSON', data)

// 新增商品
export const addGoods = (data: goodsSetting.tableDataItem) => request('/payAdminServer/product/addProduct', null, 'POST_JSON', data)

// 编辑商品
export const updGoods = (data: goodsSetting.tableDataItem) => request('/payAdminServer/product/modProduct', null, 'POST_JSON', data)

// 提交商品
export const submitGoods = (data: { productList: ({ id: number })[] }) => request('/payAdminServer/product/submitProduct', null, 'POST_JSON', data)

// 删除商品
export const delGoods = (data: { id: number, prodChnl: any }) => request('/payAdminServer/product/delProduct', null, 'POST_JSON', data)

// 上线商品
export const onlineGoods = (data: { productList: ({ id: number })[], prodChnl: string }) => request('/payAdminServer/product/onlineProduct', null, 'POST_JSON', data)

// 下线商品
export const offlineGoods = (data: { productList: ({ id: number })[], prodChnl: string }) => request('/payAdminServer/product/offlineProduct', null, 'POST_JSON', data)

// 校验商品
export const verifyGoodsPrice = (data: goodsSetting.verifyGoodsPriceParams) => request('/payAdminServer/product/checkNativePriceRange', null, 'POST_JSON', data)

// 获取详情基础方案模块下拉框数据
export const queryBasicPlanSelections = (data: {prodChnl: string}) => request('/payAdminServer/product/getMetadata', null, 'POST_JSON', data)

// 导入商品
export const importGoods = (data: any) => request('/payAdminServer/product/importProduct', null, 'POST_FILE', data)

// 导出商品
export const exportGoods = (data: any) => request('/payAdminServer/product/exportProduct', null, 'POST_DOWNLOAD', data)

// 导出csv
export const exportGoodsCsv = (data: any) => request('/payAdminServer/product/exportProductByChannel', null, 'POST_DOWNLOAD', data)

// 获取产品渠道
export const getProdChannelCodeList = (data: any) =>
  request('/payAdminServer/project/product/getProdChannelCodeList', null, 'POST_JSON', data)

// 批量修改价格
export const batchChangePrice = (data: {
    changePriceType: number,
    productList: { id: number }[],
}) =>
  request('/payAdminServer/product/product/batchChangePrice', null, 'POST_JSON', data)

// 翻译
export const translator = (data: any) => request('/payAdminServer/product/translator', null, 'POST_JSON', data)

// 获取历史价格接口
export const getLastLangInfo = (data: any) => request('/payAdminServer/product/lastLangInfo', null, 'POST_JSON', data)